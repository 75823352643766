import { Component, Prop } from 'vue-property-decorator';
import { Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { VueComponentMixin } from '~/utils/vue-component';
import { CztWidgets } from '~/utils/views/widgets';

import { getModule } from 'vuex-module-decorators';
import { PageLoader } from '~/components/templates/common';
import SafeTravelsListFilter, { FilterEmit } from './SafeTravelsFilter';
import SafeTravelsModule from '~/app/core/store/modules/SafeTravelsModule';
import { Link } from '~/components/molecules';
import { SafeTravelsListInterface, SafeTravelsListItem } from './types';

const rootClass = 'czt-safe-travels-list';

@Component
export default class SafeTravelsList
  extends VueComponentMixin<SafeTravelsListInterface, PrefetchComponent>(
    Prefetch
  )
  implements SafeTravelsListInterface {
  @Prop()
  public anchorId?: string;

  @Prop()
  public anchorName?: string;

  @Prop({ required: true, type: String })
  public title!: string;

  @Prop({ required: true, type: String })
  public guid!: string;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  public className = CztWidgets.SAFE_TRAVELS;

  protected filterData: FilterEmit = {
    categories: [],
    fulltext: '',
  };

  protected get safeTravelsModule(): SafeTravelsModule {
    return getModule(SafeTravelsModule, this.$store);
  }

  protected get items() {
    let items: SafeTravelsListItem[] = this.safeTravelsModule.items;
    if (this.filterData.categories.length > 0) {
      items = items.filter((item) => {
        return (
          item.categories.filter(
            (category) => this.filterData.categories.indexOf(category) !== -1
          ).length > 0
        );
      });
    }
    if (this.filterData.fulltext && this.filterData.fulltext.trim() !== '') {
      items = items.filter((item) => {
        return (
          item.name
            .toLowerCase()
            .normalize('NFKD')
            .replace(/[^\w\s.-_\/]/g, '')
            .indexOf(
              this.filterData.fulltext
                .toLowerCase()
                .normalize('NFKD')
                .replace(/[^\w\s.-_\/]/g, '')
            ) !== -1
        );
      });
    }
    return items;
  }

  protected get categories() {
    return this.safeTravelsModule.categories;
  }

  public prefetch() {
    const safeTravelsModule =
      this.safeTravelsModule || getModule(SafeTravelsModule, this.$store);
    return safeTravelsModule.getSafeTravels(this.$i18n.locale);
  }

  public render() {
    return <v-sheet class={rootClass}>{this.renderContent()}</v-sheet>;
  }

  protected renderContent(): JSX.Element {
    const containerClasses = ['czt-spacer'];

    if (this.isTopSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-top');
    }
    if (this.isBottomSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-bottom');
    }

    return (
      <v-container id={this.anchorId} class={containerClasses.join(' ')}>
        <v-row>{this.getHeadline()}</v-row>
        <SafeTravelsListFilter
          onFilter={this.filter}
          loading={this.safeTravelsModule.loading}
          filterOptions={{ categories: this.categories }}
        />
        <v-row>
          <v-col>
            <v-simple-table>
              <thead>
                <tr>
                  <th class='text-left'>
                    {this.$t('app.safeTravels.table.name')}
                  </th>
                  <th class='text-left'>
                    {this.$t('app.safeTravels.table.link')}
                  </th>
                </tr>
              </thead>
              <tbody>{this.getSafeTravelItems()}</tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
    );
  }

  protected getHeadline(): JSX.Element | undefined {
    if (this.items.length === 0) {
      return;
    }

    return (
      <v-col class='py-0'>
        <Headline underscore align={Align.LEFT} level={2}>
          {this.title}
        </Headline>
      </v-col>
    );
  }

  protected getSafeTravelItems(): JSX.Element | JSX.Element[] {
    if (this.items.length < 1) {
      if (this.safeTravelsModule.loading) {
        return (
          <v-col cols='12'>
            <PageLoader />
          </v-col>
        );
      }

      return (
        <v-col cols='12'>
          <Headline align={Align.CENTER} level={3}>
            {this.$t('app.search.noResults')}
          </Headline>
        </v-col>
      );
    }

    return this.items.map((item) => {
      const title: string = new URL(item.link).hostname;
      return (
        <tr key={`${item.id}__safe-travels__item`}>
          <td>{item.name}</td>
          <td>
            <Link url={item.link}>{title}</Link>
          </td>
        </tr>
      );
    });
  }

  protected filter(data: FilterEmit): void {
    this.filterData.categories = data.categories;
    this.filterData.fulltext = data.fulltext;
  }
}
